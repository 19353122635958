<template>
  <a-button
    class="editable-add-btn"
    @click="openModal"
    style="margin-bottom: 16px"
  >Добавить роль</a-button>

  <a-modal
    title="Добавить роль"
    :visible="modalVisible"
    :closable="true"
    @cancel="resetForm"
  >
    <template #footer>
      <a-button key="cancel" @click="resetForm">Отмена</a-button>
      <a-button key="ok" type="primary" :loading="!(!loading && !confirmLoading)" @click="create">ОК</a-button>
    </template>
    <a-form :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-item label="Название">
        <a-input
          ref="roleNameInput"
          placeholder="Введите название роли"
          v-model:value="roleName" />
      </a-form-item>
      <a-form-item label="Переводы" v-if="roleLocales.length">
        <div class="container" v-for="(model, key) in roleLocales" :key="key">
          <div class="row">
            <div class="col-md-12 pb-1">
              <div class="row">
                <a-input placeholder="Введите название" v-model:value="model.title"></a-input>
              </div>
            </div>
            <div class="col-md-12 pb-1">
              <div class="row">
                <a-input placeholder="Название во множественном числе" v-model:value="model.plural"></a-input>
              </div>
            </div>
            <div class="col-md-12 pb-1">
              <div class="row">
                <a-select v-model:value="model.locale">
                  <a-select-option v-for="(locale, lKey) in props.locales" :key="lKey" :value="locale.short">{{ locale.name }}</a-select-option>
                </a-select>
              </div>
            </div>
          </div>
          <hr v-if="(key + 1) !== roleLocales.length" />
        </div>
      </a-form-item>
      <div class="row mx-5 justify-content-end">
        <a-button
          @click="addLocale"
          shape="round"
          type="link"
          class="px-0 mb-5"
        >
          <template #icon>
            <PlusCircleOutlined />
          </template>Добавить перевод</a-button>
      </div>
      <a-form-item label="Показать в списке">
        <a-switch v-model:checked="show" />
      </a-form-item>
      <a-form-item label="Уведомление (счета)">
        <a-switch v-model:checked="bill_notification" />
      </a-form-item>
      <a-form-item label="Уведомление (чаевые)">
        <a-switch v-model:checked="tips_notification" />
      </a-form-item>
      <a-form-item label="Уведомление (заказы)">
        <a-switch v-model:checked="order_notification" />
      </a-form-item>
      <a-form-item label="Позвать официанта">
        <a-switch v-model:checked="call_waitress" />
      </a-form-item>
      <a-form-item label="Позвать кальянщика">
        <a-switch v-model:checked="call_hookah" />
      </a-form-item>
      <a-form-item label="Попросить счет">
        <a-switch v-model:checked="call_bill" />
      </a-form-item>
      <a-form-item label="Предупреждения">
        <a-switch v-model:checked="attention" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script setup>
import apiClient from "@/services/axios";
import {ref, nextTick} from "vue";
import { PlusCircleOutlined } from '@ant-design/icons-vue'

const props = defineProps({
  locales: {
    type: Array,
    default: null,
  },
})

const emit = defineEmits(['reload'])

let loading = ref(false),
  confirmLoading = ref(false),
  modalVisible = ref(false),
  labelCol = { span: 9 },
  wrapperCol = { span: 13 },
  roleName = ref(''),
  roleNameInput = ref("roleNameInput"),
  show = ref(true),
  tips_notification = ref(false),
  bill_notification = ref(false),
  order_notification = ref(false),
  call_waitress = ref(false),
  call_hookah = ref(false),
  call_bill = ref(false),
  attention = ref(false),
  roleLocales = ref([]);

const
  openModal = () => {
    modalVisible.value = true;
    nextTick(() => roleNameInput.value?.focus());
  },
  resetForm = () => {
    roleName.value = '';
    show.value = true;
    modalVisible.value = false;
  },
  addLocale = () => {
    roleLocales.value.push({
      id: null,
      title: null,
      plural: null,
      locale: props.locales.find(() => true).short,
    })
  },
  create = () => {
    confirmLoading.value = true;
    return apiClient.post('/employee/create-role', {
      name: roleName.value,
      show: show.value,
      bill_notification: bill_notification.value,
      tips_notification: tips_notification.value,
      order_notification: order_notification.value,
      call_waitress: call_waitress.value,
      call_hookah: call_hookah.value,
      call_bill: call_bill.value,
      attention: attention.value,
      locales: roleLocales.value
    })
    .then(response => {
      if (response) {
        resetForm()
        modalVisible.value = false;
        confirmLoading.value = false;
        emit('reload')
      }
    }).catch(() => {
      modalVisible.value = false;
      confirmLoading.value = false;
      emit('reload')
      // console.log(err)
    }).finally(() => {
      confirmLoading.value = false;
      emit('reload')
    })
  };
</script>
