<template>
  <div>
    <vb-create-employee-role :locales="locales" @reload="reload()" />
    <vb-edit-employee-role
      v-if="modalVisible"
      :role-id="roleId"
      :locales="locales"
      @reload="reload()"
      @hide-modal="hideModal()" />
    <a-table
      :columns="columns"
      :data-source="dataSource"
      :loading="loading"
      :pagination="false"
      @change="tableChange"
      row-key="id"
    >
      <template #show="{ record }">
        <a-tag
          @click="updateColumn(record.id, record.name, 'show', 0)"
          v-if="record.show"
          color="#108ee9"
          class="cursor-pointer"
        >Да</a-tag>
        <a-tag
          @click="updateColumn(record.id, record.name, 'show', 1)"
          class="cursor-pointer"
          v-else
        >Нет</a-tag>
      </template>
      <template #tips_notification="{ record }">
        <a-tag
          @click="updateColumn(record.id, record.name, 'tips_notification', 0)"
          v-if="record.tips_notification"
          color="#108ee9"
          class="cursor-pointer"
        >Да</a-tag>
        <a-tag
          @click="updateColumn(record.id, record.name, 'tips_notification', 1)"
          class="cursor-pointer"
          v-else
        >Нет</a-tag>
      </template>
      <template #bill_notification="{ record }">
        <a-tag
          @click="updateColumn(record.id, record.name, 'bill_notification', 0)"
          v-if="record.bill_notification"
          color="#108ee9"
          class="cursor-pointer"
        >Да</a-tag>
        <a-tag
          @click="updateColumn(record.id, record.name, 'bill_notification', 1)"
          class="cursor-pointer"
          v-else
        >Нет</a-tag>
      </template>
      <template #order_notification="{ record }">
        <a-tag
          @click="updateColumn(record.id, record.name, 'order_notification', 0)"
          v-if="record.order_notification"
          color="#108ee9"
          class="cursor-pointer"
        >Да</a-tag>
        <a-tag
          @click="updateColumn(record.id, record.name, 'order_notification', 1)"
          class="cursor-pointer"
          v-else
        >Нет</a-tag>
      </template>
      <template #call_waitress="{ record }">
        <a-tag
            @click="updateColumn(record.id, record.name, 'call_waitress', 0)"
            v-if="record.call_waitress"
            color="#108ee9"
            class="cursor-pointer"
        >Да</a-tag>
        <a-tag
            @click="updateColumn(record.id, record.name, 'call_waitress', 1)"
            class="cursor-pointer"
            v-else
        >Нет</a-tag>
      </template>
      <template #call_hookah="{ record }">
        <a-tag
            @click="updateColumn(record.id, record.name, 'call_hookah', 0)"
            v-if="record.call_hookah"
            color="#108ee9"
            class="cursor-pointer"
        >Да</a-tag>
        <a-tag
            @click="updateColumn(record.id, record.name, 'call_hookah', 1)"
            class="cursor-pointer"
            v-else
        >Нет</a-tag>
      </template>
      <template #call_bill="{ record }">
        <a-tag
            @click="updateColumn(record.id, record.name, 'call_bill', 0)"
            v-if="record.call_bill"
            color="#108ee9"
            class="cursor-pointer"
        >Да</a-tag>
        <a-tag
            @click="updateColumn(record.id, record.name, 'call_bill', 1)"
            class="cursor-pointer"
            v-else
        >Нет</a-tag>
      </template>
      <template #attention="{ record }">
        <a-tag
          @click="updateColumn(record.id, record.name, 'attention', 0)"
          v-if="record.attention"
          color="#108ee9"
          class="cursor-pointer"
        >Да</a-tag>
        <a-tag
          @click="updateColumn(record.id, record.name, 'attention', 1)"
          class="cursor-pointer"
          v-else
        >Нет</a-tag>
      </template>
      <template #action="{ record }">
        <div class="w-100 text-right">
          <a-tooltip placement="top" class="mx-2" v-if="record.id !== 1">
            <template #title>Редактировать</template>
            <a @click="showModal(record.id)"><i class="fe fe-edit"></i></a>
          </a-tooltip>
          <a-tooltip placement="top" class="mx-2" v-if="record.id !== 1">
            <template #title>Удалить</template>
            <a @click="deleteModal(record.id)"><i class="fe fe-trash"></i></a>
          </a-tooltip>
        </div>
      </template>
    </a-table>
    <div class="mt-4 text-right">
      <a-pagination
        show-size-changer
        v-if="total > query['per-page'].value"
        v-model:pageSize="query['per-page'].value"
        v-model:current="query.page.value"
        :total="total"
      />
    </div>
  </div>
</template>

<script setup>
import VbCreateEmployeeRole from "@/views/employee/role/create";
import { onMounted, reactive, ref, toRefs, watch } from 'vue';
import VbEditEmployeeRole from "@/views/employee/role/edit";
import { useRoute, useRouter } from "vue-router";
import { message, Modal } from 'ant-design-vue'
import apiClient from "@/services/axios";

let
  locales = ref([]),
  roleId = ref(0),
  dataSource = ref([]),
  loading = ref(false),
  modalVisible = ref(false),
  total = ref(0);

const router = useRouter(),
  route = useRoute(),
  initialParams = {
    page: 1,
    'per-page': 10,
    sort: '',
    name: '',
  },
  queryParams = reactive({ ...initialParams }),
  columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: true,
    },
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
    },
    {
      title: 'Показать в списке',
      dataIndex: 'show',
      key: 'show',
      sorter: true,
      slots: { customRender: 'show' },
    },
    {
      title: 'Уведомления об оплате счётов',
      dataIndex: 'bill_notification',
      key: 'bill_notification',
      sorter: true,
      slots: { customRender: 'bill_notification' },
    },
    {
      title: 'Уведомления о чаевых',
      dataIndex: 'tips_notification',
      key: 'tips_notification',
      sorter: true,
      slots: { customRender: 'tips_notification' },
    },
    {
      title: 'Уведомления о заказах',
      dataIndex: 'order_notification',
      key: 'order_notification',
      sorter: true,
      slots: { customRender: 'order_notification' },
    },
    {
      title: 'Позвать официанта',
      dataIndex: 'call_waitress',
      key: 'call_waitress',
      sorter: true,
      slots: { customRender: 'call_waitress' },
    },
    {
      title: 'Позвать кальянщика',
      dataIndex: 'call_hookah',
      key: 'call_hookah',
      sorter: true,
      slots: { customRender: 'call_hookah' },
    },
    {
      title: 'Попросить счет',
      dataIndex: 'call_bill',
      key: 'call_bill',
      sorter: true,
      slots: { customRender: 'call_bill' },
    },
    {
      title: 'Предупреждения',
      dataIndex: 'attention',
      key: 'attention',
      sorter: true,
      slots: { customRender: 'attention' },
    },
    {
      key: 'action',
      slots: { customRender: 'action' },
    },
  ],
  updateParams = params => {
    Object.assign(queryParams, params)
  },
  tableChange = (pag, filters, sorter) => {
    if (typeof sorter.order == "string") {
      let sign = ''
      if (sorter.order === 'descend')
        sign = '-'
      updateParams({ sort: sign + sorter.field })
    }
  },
  showModal = (id) => {
    roleId.value = id;
    modalVisible.value = true
  },
  hideModal = () => {
    setTimeout(() => {
      modalVisible.value = false
    }, 100)
  },
  updateColumn = (id, name, column, value) => {
    let data = {};
    data['name'] = name;
    data[column] = value;
    apiClient.put(`/employee/update-role?id=${id}`, data).then(response => {
      if (response) {
        message.success('Роль успешно обновлен')
      }
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      reload()
    });
  },
  deleteModal = id => {
    Modal.confirm({
      title: 'Удаление роли',
      content: `Вы действительно хотите удалить роль?`,
      okText: 'Да',
      cancelText: 'Нет',
      onOk() {
        return apiClient.delete(`employee/delete-role?id=${id}`).then(response => {
          if (response) {
            message.success('Роль успешно удален')
          }
        }).catch(() => {
          // console.log(err)
        }).finally(() => {
          reload()
        });
      },
    });
  },
  getData = (params) => {
    loading.value = true
    return apiClient.get('/employee/roles', { params }).then(({data}) => {
      dataSource.value = data.data.items
      total.value = data.data.total
      locales.value = data.data.locales
      loading.value = false
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      loading.value = false
    });
  },
  reload = () => {
    getData({
      ...queryParams,
    })
  };

onMounted(() => {
  getData({
    ...queryParams,
  })
})

watch(queryParams, () => {
  router.push({
    name: 'employee-roles',
    query: {
      ...route.query,
      ...queryParams,
    },
  })
  getData({
    ...route.query,
    ...queryParams,
  })
})

const query = {...toRefs(queryParams)}

</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
