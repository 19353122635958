<template>
  <a-modal
    title="Редактировать роль"
    :visible="modalVisible"
    :closable="true"
    @cancel="resetForm"
  >
    <template #footer>
      <a-button key="cancel" @click="resetForm">Отмена</a-button>
      <a-button key="ok" type="primary" :loading="!(!loading && !confirmLoading)" @click="update">ОК</a-button>
    </template>
    <a-form :label-col="labelCol" :wrapper-col="wrapperCol">
      <div class="spin-loader" v-if="fetching">
        <a-spin />
      </div>
      <div v-else>
        <a-form-item label="Название">
          <a-input
            ref="roleNameInput"
            id="rolename"
            placeholder="Введите название роли"
            v-model:value="roleName" />
        </a-form-item>
        <a-form-item label="Переводы" v-if="roleLocales.length">
          <div class="container pb-3" v-for="(model, key) in roleLocales" :key="key">
            <div class="row">
              <div class="col-md-12 pb-1">
                <div class="row">
                  <a-input placeholder="Введите название" v-model:value="model.title"></a-input>
                </div>
              </div>
              <div class="col-md-12 pb-1">
                <div class="row">
                  <a-input placeholder="Название во множественном числе" v-model:value="model.plural"></a-input>
                </div>
              </div>
              <div class="col-md-12 pb-1">
                <div class="row">
                  <a-select v-model:value="model.locale">
                    <a-select-option v-for="(locale, lKey) in props.locales" :key="lKey" :value="locale.short">{{ locale.name }}</a-select-option>
                  </a-select>
                </div>
              </div>
              <div class="col-md-12">
                <div class="row justify-content-end">
                  <a-button @click="removeLocale(key)" type="link" danger>Удалить <MinusCircleOutlined /></a-button>
                </div>
              </div>
            </div>
          </div>
        </a-form-item>
        <div class="row mx-5 justify-content-end">
          <a-button
            @click="addLocale"
            shape="round"
            type="link"
            class="px-0 mb-5"
          >
            <template #icon>
              <PlusCircleOutlined />
            </template>Добавить перевод</a-button>
        </div>
        <a-form-item label="Показать в списке">
          <a-switch v-model:checked="show" />
        </a-form-item>
        <a-form-item label="Уведомление (счета)">
          <a-switch v-model:checked="bill_notification" />
        </a-form-item>
        <a-form-item label="Уведомление (чаевые)">
          <a-switch v-model:checked="tips_notification" />
        </a-form-item>
        <a-form-item label="Уведомление (заказы)">
          <a-switch v-model:checked="order_notification" />
        </a-form-item>
        <a-form-item label="Позвать официанта">
          <a-switch v-model:checked="call_waitress" />
        </a-form-item>
        <a-form-item label="Позвать кальянщика">
          <a-switch v-model:checked="call_hookah" />
        </a-form-item>
        <a-form-item label="Попросить счет">
          <a-switch v-model:checked="call_bill" />
        </a-form-item>
        <a-form-item label="Предупреждения">
          <a-switch v-model:checked="attention" />
        </a-form-item>
      </div>
    </a-form>
  </a-modal>
</template>

<script setup>
import {ref, onMounted, nextTick} from "vue";
import apiClient from "@/services/axios";
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons-vue'

const props = defineProps({
  locales: {
    type: Array,
    default: null,
  },
  roleId: {
    type: Number,
    default: 0,
  },
})

const emit = defineEmits(['hide-modal', 'reload']);

let fetching = ref(true),
  loading = ref(false),
  confirmLoading = ref(false),
  modalVisible = ref(true),
  labelCol = { span: 9 },
  wrapperCol = { span: 13 },
  roleName = ref(''),
  roleNameInput = ref("roleNameInput"),
  show = ref(true),
  tips_notification = ref(false),
  bill_notification = ref(false),
  order_notification = ref(false),
  call_waitress = ref(false),
  call_hookah = ref(false),
  call_bill = ref(false),
  attention = ref(false),
  roleLocales = ref([]);

const
  resetForm = () => {
    roleName.value = '';
    show.value = true;
    modalVisible.value = false;
    emit('hide-modal')
  },
  addLocale = () => {
    roleLocales.value.push({
      id: null,
      title: null,
      plural: null,
      locale: props.locales.find(() => true).short,
    })
  },
  removeLocale = (key) => {
    roleLocales.value.splice(key, 1)
  },
  update = () => {
    confirmLoading.value = true;

    return apiClient.put(`/employee/update-role?id=${props.roleId}`, {
      name: roleName.value,
      show: show.value,
      bill_notification: bill_notification.value,
      tips_notification: tips_notification.value,
      order_notification: order_notification.value,
      call_waitress: call_waitress.value,
      call_hookah: call_hookah.value,
      call_bill: call_bill.value,
      attention: attention.value,
      locales: roleLocales.value
    })
    .then(response => {
      if (response) {
        resetForm()
        modalVisible.value = false;
        confirmLoading.value = false;
        emit('reload')
      }
    }).catch(() => {
      modalVisible = false;
      confirmLoading.value = false;
      emit('reload')
      emit('hide-modal')
      // console.log(err)
    }).finally(() => {
      confirmLoading.value = false;
      modalVisible.value = false;
      emit('reload')
      emit('hide-modal')
    })
  };

onMounted(() => {
  apiClient.get(`/employee/role?id=${props.roleId}`).then(response => {
    if (response) {
      roleName.value = response.data.data.name
      show.value = response.data.data.show === 1
      bill_notification.value = response.data.data.bill_notification === 1
      tips_notification.value = response.data.data.tips_notification === 1
      order_notification.value = response.data.data.order_notification === 1
      call_waitress.value = response.data.data.call_waitress === 1
      call_hookah.value = response.data.data.call_hookah === 1
      call_bill.value = response.data.data.call_bill === 1
      attention.value = response.data.data.attention === 1
      roleLocales.value = response.data.data.locales
    }
    fetching.value = false
  }).catch(() => {
    // console.log(err)
    fetching.value = false
  }).finally(() => {
    fetching.value = false;
    nextTick(() => roleNameInput.value?.focus());
  })
});

</script>
